<template>
  <div class="free-order-gather">
    <van-nav-bar
        left-arrow
        left-text="返回"
        @click-left="goBack"
        :title="moduleTitle"
    />
    <van-radio-group v-model="selDateType" direction="horizontal" class="date-radio">
      <van-radio name="1">七天</van-radio>
      <van-radio name="2">近一月</van-radio>
      <van-radio name="3">自定义</van-radio>
      <van-button type="primary" v-show="selDateType !== '3'" @click="onConfirm">查询</van-button>
    </van-radio-group>
    <van-cell-group class="date-cell-group" v-show="selDateType === '3'">
      <van-cell title="选择日期区间" :value="selDate" @click="show = true" is-link/>
      <van-calendar v-model="show" type="range" @confirm="onConfirm" :min-date="minDate" :allow-same-day="true"/>
    </van-cell-group>

    <div id="drawDivWXTotal" style="width: 100%;height:400px;" class="draw-div" v-show="isLoad"></div>
    <div id="drawDivAZTotal" style="width: 100%;height:400px;" class="draw-div" v-show="isLoad"></div>
    <template v-for="(drawDiv, index) in drawDivs">
      <div :id="'drawWXDiv' + index" style="width: 100%;height:400px;" class="draw-div"></div>
      <div :id="'drawAZDiv' + index" style="width: 100%;height:400px;" class="draw-div"></div>
    </template>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
export default {
  name: "Analysis1007",
  data() {
    return {
      moduleTitle: '',
      selDateType: '1',
      minDate: new Date(2020,1,1),
      show: false,
      miniReqParams: {
        //0=今天，1=时间区间
        timeConditionType: '0',
        startDate: '',
        endDate: '',
        month: ''
      },
      drawDivs: [],
      existCharts: [],
      isLoad: false,
      xAxis: []
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    onConfirm(date) {
      if(this.selDateType === '3') {
        const [start, end] = date
        this.show = false
        this.miniReqParams.timeConditionType = '1'
        this.miniReqParams.startDate = this.dateUtils.formatDate(start)
        this.miniReqParams.endDate = this.dateUtils.formatDate(end)
      } else {
        if(this.selDateType === '1') {
          this.miniReqParams.startDate = this.dateUtils.getBeforeDate(7)
          this.miniReqParams.endDate = this.dateUtils.formatDate(new Date())
        } else {
          this.miniReqParams.startDate = this.dateUtils.getBeforeDate(30)
          this.miniReqParams.endDate = this.dateUtils.formatDate(new Date())
        }
      }
      this.$toast.loading({ message: '请求中', overlay: true, duration: 0 })
      this.sendQuery();
    },
    sendQuery() {
      //发送查询请求
      this.postRequest('/wechat-analysis/fee-order-gather', this.miniReqParams).then(resp => {
        if(resp) {
          if(this.existCharts.length > 0) {
            this.existCharts.forEach(charts => {
              charts.dispose()
            })
            this.existCharts = []
          }
          const data = resp.data
          if(data) {
            this.drawDivs = Object.keys(data.data)
            this.isLoad = true
            this.xAxis = data.xaxis
            this.$nextTick(() => {
              this.drawTotalDivs(data.sumData);
              this.drawCharts(data.data);
            })
          } else {
            this.$toast("查询无数据，请换条件查询！")
          }
        }
        this.$toast.clear()
      })
    },
    drawTotalDivs(data) {
      const legendGroup = ['工单数','收费订单数','收费金额']
      for (let i = 0; i < 2; i++) {
        let params = {}
        let series = [];
        let chargeCount = []
        let workCount = []
        let chargeAmount = []
        // 遍历出对应列
        data.forEach(value => {
          if (i === 0) {
            chargeCount.push(value.wxFeeCut)
            workCount.push(value.wxCut)
            chargeAmount.push(value.wxFeeAmount)
          } else {
            chargeCount.push(value.azFeeCut)
            workCount.push(value.azCut)
            chargeAmount.push(value.azFeeAmount)
          }
        })
        series.push({
          name: legendGroup[0],
          stack: 'total',
          type: 'bar',
          data: workCount
        })
        series.push({
          name: legendGroup[1],
          stack: 'total',
          type: 'bar',
          data: chargeCount
        })
        series.push({
          name: legendGroup[2],
          stock: 'Amount',
          type: 'line',
          yAxisIndex: 1,
          data: chargeAmount
        })
        params.title = i === 0 ? '维修总计' : '安装总计'
        params.legend = legendGroup
        params.series = series
        this.myEcharts(i === 0 ? 'drawDivWXTotal' : 'drawDivAZTotal', params)
      }
    },
    drawCharts(data) {
      Object.keys(data).forEach((company, index) => {
        const legendGroup = ['工单数','收费订单数','收费金额']
        for (let i = 0; i < 2; i++) {
          let params = {}
          let series = [];
          let chargeCount = []
          let workCount = []
          let chargeAmount = []
          // 遍历出对应列
          data[company].forEach(value => {
            if (i === 0) {
              chargeCount.push(value.wxFeeCut)
              workCount.push(value.wxCut)
              chargeAmount.push(value.wxFeeAmount)
            } else {
              chargeCount.push(value.azFeeCut)
              workCount.push(value.azCut)
              chargeAmount.push(value.azFeeAmount)
            }
          })
          series.push({
            name: legendGroup[0],
            stack: 'total',
            type: 'bar',
            data: workCount
          })
          series.push({
            name: legendGroup[1],
            stack: 'total',
            type: 'bar',
            data: chargeCount
          })
          series.push({
            name: legendGroup[2],
            stock: 'Amount',
            type: 'line',
            yAxisIndex: 1,
            data: chargeAmount
          })
          params.title = i === 0 ? company+ '维修' : company + '安装'
          params.legend = legendGroup
          params.series = series
          this.myEcharts(i === 0 ? 'drawWXDiv' + index : 'drawAZDiv' + index, params)
        }
      })
    },
    myEcharts(id, params){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById(id))
      // 指定图表的配置项和数据
      let option = {
        title: {
          text: params.title
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          top: '6%',
          data: params.legend
        },
        grid: {
          top: '20%',
          left: '2%',
          right: '5%',
          bottom: '1%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.xAxis
        },
        yAxis: [{
          type: 'value',
          name: '单数',
          axisLabel: {
            formatter: function (value, index) {
              if (value >= 1000 && value < 10000) {
                value = value / 1000 + '千'
              } else if (value >= 10000) {
                value = value / 10000 + '万'
              }
              return value
            }
          }
        },
        {
          type: 'value',
          name: '金额',
          axisTick: {
            inside: true
          },
          scale: true,
          axisLabel: {
            margin: 2,
            formatter: function (value, index) {
              //y轴的数值显示格式化主要调整部分
              if (value >= 10000 && value < 10000000) {
                value = value / 10000 + '万';
              }
              return value;
            }
          }
        }],
        series: params.series
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      this.existCharts.push(myChart);
    }
  },
  computed: {
    selDate() {
      if(this.miniReqParams.startDate.length > 0
          && this.miniReqParams.endDate.length > 0) {
        return `${this.miniReqParams.startDate}-${this.miniReqParams.endDate}`
      }
    }
  },
  mounted: function () {
    this.moduleTitle = window.sessionStorage.getItem("moduleTitle");
  }
}
</script>

<style scoped>
  .date-radio {
    margin-top: 15px;
    background-color: #fff;
    padding: 10px 16px;
  }
  .date-radio>button {
    margin-left: auto;
  }
  .draw-div {
    margin-top: 15px;
    background-color: white;
  }
  .van-nav-bar__content {
    margin-bottom: 10px;
  }
  .work-order-gather {
    padding-bottom: 15px;
    width: 100%;
  }
  .date-cell-group {
    margin: 15px 0;
  }
  .van-cell__value {
    color: #999;
  }
</style>
